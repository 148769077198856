@import '../../../../styles/variables';

/* by default font-weight is 400, font-size is 21px, line-height is 25px */

.typography {
  font-size: $font-size-xl;
  line-height: 1.2;
  text-overflow: ellipsis;
  // @todo: remove if nothing bad happens
  //overflow: hidden;

  &_weight-light {
    font-weight: 300;
  }

  &_weight-bold {
    font-weight: 700;
  }

  &_size-12 {
    font-size: $font-size-xs;
  }

  &_size-14 {
    font-size: $font-size-s;
  }

  &_size-16 {
    font-size: $font-size-m;
  }

  &_size-18 {
    font-size: $font-size-l;
  }

  &_size-32 {
    font-size: $font-size-xxl;
  }

  &_line-height-15 {
    line-height: 15px;
  }

  &_line-height-17 {
    line-height: 17px;
  }

  &_line-height-19 {
    line-height: 19px;
  }

  &_line-height-22 {
    line-height: 22px;
  }

  &_line-height-24 {
    line-height: 24px;
  }

  &_line-height-30 {
    line-height: 30px;
  }

  &_line-height-32 {
    line-height: 32px;
  }

  &_line-height-56 {
    line-height: 56px;
  }

  &_color-default {
    color: $color-black;
  }

  &_color-white {
    color: $color-white;
  }

  &_color-inherit {
    color: inherit;
  }

  &_color-primary {
    color: $color-primary;
  }

  &_align-center {
    text-align: center;
  }

  &_text-transform-uppercase {
    text-transform: uppercase;
  }

  &_text-transform-capitalize {
    text-transform: capitalize;
  }
}
