@import "../../../../styles/variables";

.root {
  color: white;
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFoundWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 16px;
  max-height: 455px;
  height: 100%;
}

.icon {
  margin: 16px 16px 0;
}

.icon svg {
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 218px);
}

.text {
  text-align: center;
}

.title {
  margin: 0 16px 16px;
}

.description {
  color: $color-gray;
}

.homeLink {
  margin: 0 16px 16px;
}
