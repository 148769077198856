@import 'reset';
@import 'fonts';
@import 'variables';
@import 'media-queries';

html {
  box-sizing: border-box;
  background-color: $color-white;
  color: $color-white;
  font-family: Lato, sans-serif;
  font-size: $font-size-default;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

a {
  //color: $color-gray-dark;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input {
  &::placeholder {
    //color: rgba(46, 56, 77, 0.24);
  }

  &:hover {
    //border-color: #8798ad;
  }

  &:focus {
    //border-color: #69707f;
  }
}

:link {
  text-decoration: none;
}

button {
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  color: currentColor;
  cursor: pointer;
}
