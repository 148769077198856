@import '../../../../styles/variables';

.button {
  min-width: 80px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 19px 24px 18px;
  background-color: $color-black;
  color: $color-white;
  font-weight: 700;
  font-size: $font-size-m;
  text-transform: uppercase;
  transition-property: background-color, color;
  transition-duration: 300ms;
  transition-timing-function: $easing--decelerated;

  &:not(.button--disabled) {
    &:hover {
      background-color: $color-gray-darker;
      cursor: pointer;
    }

    &:active {
      background-color: $color-gray-lightest;
      color: $color-black;
    }
  }

  &.button--disabled,
  &--link.button--disabled {
    pointer-events: none;
  }

  &--with-icon {
    display: flex;
    align-items: center;
    padding: 16px 24px 16px 16px;
  }

  &--secondary {
    background-color: $color-gray-lightest;
    color: $color-black;

    &:not(.button--disabled) {
      &:hover {
        background-color: $color-gray-lighter;
      }

      &:active {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  &--icon-view {
    min-width: 0;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.button--disabled) {
      &:hover {
        //the same as default button
      }

      &:active {
        background-color: $color-white;
        color: $color-black;
      }
    }

    &.button--disabled {
      // @todo: style via 'color' instead
      svg {
        opacity: 0.24;
      }
    }
  }

  &--highlighted {
    background-color: $color-primary;
    color: $color-white;

    &:not(.button--disabled) {
      &:hover {
        background-color: $color-primary-lighter;
      }

      &:active {
        background-color: $color-primary-darker;
        color: $color-white;
      }
    }
  }

  &--has-badge {
    position: relative;
  }
}

.button--with-icon .button__icon-wrapper {
  margin-right: 16px;
}

.button__badge {
  position: absolute;
  right: 2px;
  top: 2px;
  min-width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 16px;
  background-color: $color-primary-lighter;
  font-size: $font-size-xxs;
  color: $color-black;
}

.button__badge--empty {
  height: 8px;
  min-width: 8px;
  width: 8px;
}
