.root {
  color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(236, 236, 236, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingText {
  font-size: 21px;
  color: #454545;
}
