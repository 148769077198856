@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    src:  url($font-file +'.ttf') format('truetype'),
    url($font-file +'.woff') format('woff'),
    url($font-file +'.woff2') format('woff2'),;
    font-weight: $font-weight;
    font-style: normal;
  }
}

@include font('Lato', 'assets/fonts/Lato-Bold', 700);
@include font('Lato', 'assets/fonts/Lato-Regular', 400);
@include font('Lato', 'assets/fonts/Lato-Light', 300);
