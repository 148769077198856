#root, .layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.testableFeature {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: rgba(68, 68, 68, 0.15);
  z-index: 999999;
}