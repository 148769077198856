/* color scheme */

$color-primary: rgba(124, 186, 85, 1);
$color-primary-lighter: rgba(158, 216, 122, 1);
$color-primary-darker: rgba(99, 149, 68, 1);
$color-secondary: rgba(255, 170, 36, 1);
$color-additional: rgba(248, 78, 78, 1);
$color-additional--4: rgba(248, 78, 78, 0.04);

$color-white: rgba(255, 255, 255, 1);
$color-white--01: rgba(255, 255, 255, 0.1);
$color-white--16: rgba(255, 255, 255, 0.16);
$color-white--24: rgba(255, 255, 255, 0.24);
$color-white--48: rgba(255, 255, 255, 0.48);
$color-white--64: rgba(255, 255, 255, 0.64);
$color-white--transparent: rgba(255, 255, 255, 0);
$color-gray-lightest: rgb(248, 248, 248); // #F8F8F8
$color-gray-lighter: rgba(237, 237, 237, 1); // #EDEDED
$color-gray-light: rgba(214, 214, 214, 1); // #D6D6D6
$color-gray: rgba(133, 133, 133, 1); // #858585
$color-gray-darker: rgba(43, 48, 49, 1); // #2B3031
$color-gray-darker--48: rgba(43, 48, 49, 0.48);
$color-gray-darker--64: rgba(43, 48, 49, 0.64); //#2B3031A3
$color-black: rgba(0, 0, 0, 1);
$color-black--08: rgba(0, 0, 0, 0.08);
$color-black--16: rgba(0, 0, 0, 0.16);
$color-black--24: rgba(0, 0, 0, 0.24);
$color-black--48: rgba(0, 0, 0, 0.48);
$color-black--70: rgba(0, 0, 0, 0.7);
$color-black--80: rgba(0, 0, 0, 0.8);

$color-blueGray: rgb(197, 215, 216);
$background-color: rgba(255, 255, 255, 1); //initially it was #e2e0e0;

$color-scrollbar-gray--48: rgba(133, 133, 133, 0.48);
$color-scrollbar-gray--72: rgba(133, 133, 133, 0.72);

/* typography scheme */
// todo: replace with mixins

$font-size-default: 16px;
$font-size-xxl: 2rem; /* 32px */
$font-size-xl: 1.313rem; /* 21px */
$font-size-l: 1.125rem; /* 18px */
$font-size-m: 1rem; /* 16px */
$font-size-s: 0.875rem; /* 14px */
$font-size-xs: 0.75rem; /* 12px */
$font-size-xxs: 0.625rem; /* 10px */

/* animation */
$easing--standard: cubic-bezier(0.4, 0, 0.2, 1);
$easing--decelerated: cubic-bezier(0, 0, 0.2, 1);

/* z-index hierarchy */
$z-index--overlay: 1;
$z-index--navigation: 5;
$z-index--modal: 10;
$z-index--navigation-list: 15;

/* miscellaneous */
$topbar-height: 56px;
$bottom-bar-height: 56px;
$side-bar-width: 72px;
$side-bar-width--expanded: 256px;
$cart-actions-width: 368px;
